import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Not found | JBLTX</title>
      </Helmet>

      <div className="centered-text">
      <h1>Page not found</h1>
      <p >
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />
        <br />
        <Link to="/">Go home</Link>.
      </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
